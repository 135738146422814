<template>
  <div class="home">
    <el-main>
      <el-container>
        <div class="container">
          <h1 class="title">Terms of Use</h1>
          <p>Last revised: May 24, 2023</p>
          <p>
            These Terms of Use apply in connection with any websites, online
            services, and/or applications owned or operated by Brightsight Media Inc, (“we,” “us,” or “our”), including,
            without limitation, in connection with:
          </p>
          <ul>
            <li>
              the website currently located at
              <a href="/"> privateautoquote.com </a>
              and any other website owned by us upon which these Terms of Use
              are linked (collectively, the “Site”); and
            </li>
            <li>
              any other interactive features, widgets, products, services, and
              resources offered by us and/or our affiliates, distributors, or
              licensees through, and all other content within, the Site (all
              of which are collectively referred to as the “Services”).
            </li>
          </ul>
          <p>
            <b> 1. General Provisions </b>
          </p>
          <p>
            THESE TERMS OF USE TOGETHER WITH THE PRIVACY POLICY AND ANY
            SUPPLEMENTAL TERMS, CONDITIONS, OR RULES POSTED TO A SPECIFIC AREA
            OF THE SITE/SERVICES (COLLECTIVELY, “TERMS”) SET FORTH THE LEGALLY
            BINDING TERMS GOVERNING YOUR USE OF THE SITE AND SERVICES. IF YOU
            ENTER INTO A SEPARATE WRITTEN AGREEMENT WITH US (FOR EXAMPLE, AN
            ADVERTISER OR AFFILIATE AGREEMENT) SUCH AGREEMENT WILL TAKE
            PRECEDENCE OVER THESE TERMS IN THE EVENT OF A CONFLICT BETWEEN SUCH
            AGREEMENT AND THESE TERMS.
          </p>
          <p>
            By entering the Site or using the Services, you acknowledge and
            agree to all terms, conditions, and rules stated in these Terms. You
            are not permitted or authorized to use the Site or Services if you
            do not agree to be legally bound by these Terms. Please read these
            Terms carefully.
          </p>
          <p>
            We may, in our sole and absolute discretion, modify these Terms from
            time to time and we reserve the right to make changes at any time,
            without notice or obligation, to any of the Terms, Site, or
            Services. By entering the Site or using the Services, you
            acknowledge and agree that you shall be bound by any such revisions
            as of the moment they are made. We suggest periodically visiting
            this page of the Site to review these Terms.
          </p>
          <p>
            <b> 2. Jurisdiction and Governing Law </b>
          </p>
          <p>
            By entering the Site or using the Services, you acknowledge and
            agree that they are intended for use only by citizens and residents
            of the United States of America residing within the United States of
            America aged 18 years or older and will only be governed according
            to the laws of the State of California without regard to conflicts
            of laws principles. If you are not a member of the intended audience
            (as defined above), you are prohibited from accessing the Site or
            using the Services in any way, shape or form.
          </p>
          <p>
            We may assign our rights and obligations under these Terms,
            including in connection with a merger, acquisition, a sale of assets
            or by operation of law.
          </p>
          <p><b> 3. Content </b></p>
          <p>
            The Site/Services provide you with information about insurance
            products and services and connects you with insurance agents,
            insurers, and third-party insurance service websites (such websites
            and their operators are referred to herein as “Insurance Services”)
            who may be able to provide insurance products and services. If you
            are an insurance agent, insurer, or Insurance Service, you may use
            the Site or Services to market your products to consumers or obtain
            information about consumers interested in insurance products. All of
            this information and any other information we provide to you through
            the Site/Services is referred to herein as “Content.” Although we
            make all reasonable efforts to ensure that it is correct and
            up-to-date, we cannot and do not guarantee 100% accuracy. WE
            THEREFORE DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
            LIMITATION, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
            NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE AND ALL
            WARRANTIES REGARDING SECURITY, CURRENCY, CORRECTNESS, QUALITY,
            ACCURACY, COMPLETENESS, RELIABILITY, PERFORMANCE, TIMELINESS, OR
            CONTINUED AVAILABILITY WITH RESPECT TO THE SITE AND CONTENT. To the
            extent any applicable jurisdiction does not allow the exclusion of
            certain warranties, some of the above exclusions do not apply.
          </p>
          <p>
            The Site also offers job opening information and provides ways for
            you to apply for employment. You understand that nothing contained
            on the Site constitutes an offer of employment by us.
          </p>
          <p>
            If you are a consumer seeking insurance products, please be aware
            that we are not an insurance company or an insurance agent in this
            context. We help connect individuals and businesses with companies
            that may be able to provide insurance products. We do not charge
            individuals or businesses to submit inquiries regarding obtaining
            insurance through our Site/Services, instead, we receive a payment
            from the companies with whom we connect potential buyers. We do not
            endorse or recommend any particular insurance agent, insurer, or
            Insurance Service, nor do we recommend any particular insurance
            product(s). We do not provide insurance, tax, or financial advice of
            any kind. We do not guarantee that any of the insurance agents,
            insurers, or Insurance Services with whom we may connect you will
            contact you, provide you coverage, or honor any advertised
            discounts. We do not control and are not responsible for any
            communications between you and any insurance agent, insurer, or
            Insurance Service.
          </p>
          <p>
            <b> 4. Password and Account Security </b>
          </p>
          <p>
            In certain circumstances, you may create your own account on the
            Site/Services by completing a registration process. In doing so, you
            must provide us with accurate and complete registration information
            and update us if this information changes.
          </p>
          <p>
            If you create an account, we will assign you, and/or allow you to
            select, a password within our security guidelines and requirements.
            You must keep your password confidential, and you may not permit any
            other person to use your password or your account for any purpose.
            You will be responsible for all use of your password, including,
            without limitation, any use by any authorized or unauthorized third
            party. You must notify us immediately if you believe your password
            may have been used by any unauthorized person or entity. For
            security purposes, we recommend you change your password at least
            once every 12 weeks. Under no circumstance should you respond to a
            request for your password. Our employees will never ask for your
            password. You must notify us immediately if you receive such a
            request. We reserve the right to suspend or terminate your use of
            the Site/Services with or without notice to you if we believe that
            your password is being used without permission or otherwise in a
            manner that may disrupt the Site/Services.
          </p>
          <p>
            <b> 5. Consent for Communications </b>
          </p>
          <p>
            If you provide your telephone number to us via the Site or Services,
            you give your electronic signature and consent for us to send you
            marketing calls/text messages at that number
            <b> USING AN AUTODIALER AND/OR PRERECORDED MESSAGES</b>. You also
            acknowledge that your consent to receive these marketing
            communications is not required to purchase any goods or services.
            STANDARD MESSAGE AND DATA RATES MAY APPLY.
          </p>
          <p>
            If you provide your telephone number to us via the Site or Services
            <b>
              AND GIVE CONSENT FOR COMMUNICATIONS FROM THIRD PARTIES WITH WHOM
              WE MAY SHARE YOUR INFORMATION,</b>
            you give your electronic signature and consent for us AND SUCH THIRD
            PARTIES to send you marketing calls/text messages at that number
            using an autodialer AND/OR PRERECORDED MESSAGES. You also
            acknowledge that your consent to receive these marketing
            communications is not required to purchase any goods or services.
            STANDARD MESSAGE AND DATA RATES MAY APPLY.
          </p>
          <p>
            IF YOU PARTICIPATE IN OUR SMS TEXTING PROGRAM, YOU ALSO AGREE TO OUR
            SMS TEXTING PROGRAM TERMS AND CONDITIONS LOCATED AT THE END OF THESE
            TERMS.
          </p>
          <p>
            <b>Call Recording.</b> You understand that all calls to or from
            Privateautoquote., including, without limitation, Call Transfers, may be
            monitored or recorded for the purpose of review and analysis by
            Privateautoquote. By using our website you consent to this recording and agree
            to obtain the consent of its agents, employees, contractors, and
            anyone else who may be recorded on any call to or from Privateautoquote. Call
            recordings may be stored and analyzed by Privateautoquote. for an indefinite
            period of time.
          </p>
          <p>
            <b> 6. Our Intellectual Property </b>
          </p>
          <p>
            All images, text, sound, photos, custom graphics, button icons, the
            collection and compilation and assembly thereof, and the overall
            “look and feel” and distinctiveness of the Site constitute trade
            dress and are either our property or used on this Site with
            permission. The absence on the Site of our name or logo does not
            constitute a waiver of our trademark or other intellectual property
            rights relating to such name or logo. All other product names,
            company names, marks, logos, and symbols appearing on the Site may
            be the trademarks and the property of their respective owners.
          </p>
          <p>
            You acknowledge and agree that information and services available on
            the Site/Services are protected by copyrights, trademarks, service
            marks, patents, trade secrets, or other proprietary rights and laws
            and are owned or licensed by us. Except as expressly authorized by
            us, either in these Terms or elsewhere, you agree not to sell,
            license, rent, modify, distribute, copy, reproduce, approximate,
            transmit, reverse engineer, publicly display, publicly perform,
            publish, adapt, edit, or create derivative works from the Site,
            Services, or Content. Without waiving any of the foregoing rights,
            you may print or download information from the Site for your own
            personal, non-commercial home use, provided that you keep intact all
            copyright and other proprietary notices. Systematic retrieval of
            information from the Site/Services to create or compile, directly or
            indirectly, a collection, compilation, database, or directory
            without written permission from us is prohibited.
          </p>
          <p>
            <b> 7. Digital Millennium Copyright Act Notice </b>
          </p>
          <p>
            If you believe that any material on the Site/Services infringes your
            copyright rights, please contact our designated agent for Digital
            Millennium Copyright Act notices at:
          </p>
          <p style="padding-left: 40px">
            <b>Email address: media@privateautoquote.com </b>
            <br />
            <!-- <b> Phone number: 888-888-8888</b> -->
          </p>
          <p style="margin-bottom: 1em">
            In your notice, please include:
          <ul>
            <li>Your physical or electronic signature;</li>
            <li>
              Identification of the copyrighted work you claim to have been
              infringed, or, if there are multiple copyrighted works, a list
              of such works;
            </li>
            <li>
              Identification of the material that you claim to be infringing,
              and where the material is located on the Site;
            </li>
            <li>
              Your permanent home or business (if applicable) address (no P.O.
              Boxes, please), telephone number, and email address;
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by you or
              the law; and
            </li>
            <li>
              A statement, under penalty of perjury, that the information in
              your notice is accurate.
            </li>
          </ul>
          If the notice is submitted by someone else on your behalf, the
          notice must also contain a signed and notarized statement, under
          penalty of perjury, stating that the person submitting the notice is
          authorized to act on your behalf.
          </p>
          <p><b> 8. Submissions </b></p>
          <p>
            The Site and/or Services may let you submit material to us or to
            third parties: for example, you may be able to submit a request for
            an insurance quote, send us messages, complete a “contact us” form,
            post a review/testimonial, or post to a forum or message board. In
            these Terms, we use “Submissions” to refer to any material of any
            kind that you submit to us or third parties through the Site,
            including text, files, images, photos, video, sounds, and musical or
            literary works.
          </p>
          <p>
            We are not responsible for the content of Submissions provided by
            you or any other user. We do not necessarily endorse any opinion
            contained in such material. We make no warranties or
            representations, express or implied, about Submissions, including as
            to their legality or accuracy.
          </p>
          <p>
            We reserve the right, but are under no obligation, to refuse,
            remove, or edit any of your Submissions, or to restrict, suspend, or
            terminate your access to all or any part of the Site/Services, and
            we may do this with or without giving you any prior notice.
          </p>
          <p>
            We may link Submissions or parts of Submissions to other material,
            including material submitted by other users or created by us or
            third parties. We may use Submissions for our business purposes, for
            example, to examine trends or categories or to promote, market, or
            advertise our services or the products and services of others. You
            acknowledge that we may commercially benefit from use of your
            Submissions.
          </p>
          <p>
            Each time you make a Submission, you represent and warrant as
            follows:
          </p>
          <p>
            (a) You own your Submission or have the right to submit it, and in
            submitting it you will not be infringing any rights of any third
            party, including intellectual property rights (such as copyright or
            trademark), privacy or publicity rights, rights of confidentiality,
            or rights under contract.
          </p>
          <p>
            (b) Your Submission is not illegal, obscene, defamatory,
            threatening, pornographic, harassing, hateful, in any way offensive,
            and does not encourage conduct that would be considered a criminal
            offense, and does not give rise to civil liability, violate any law,
            or is otherwise deemed inappropriate.
          </p>
          <p>
            (c) Your Submission does not identify any individual (including by
            way of name, address, picture, or video) under the age of 18 and if
            your Submission identifies any individual over the age of 18, you
            have that person’s consent to being identified in exactly that way
            in your Submission.
          </p>
          <p>
            (d) You are not impersonating any other person, intentionally or
            otherwise.
          </p>
          <p>
            (e) You will not collect usernames and/or email addresses of others
            for the purpose of sending unsolicited or unauthorized emails.
          </p>
          <p>
            (f) You will not engage in criminal or tortious activity, including
            fraud, spamming, spimming, sending of viruses or other harmful
            files, copyright infringement, patent infringement, or theft of
            trade secrets or attempt to impersonate another user or person.
          </p>
          <p>
            (g) You will not engage in any automated use of the system, such as
            using scripts to alter our Content.
          </p>
          <p>
            (h) You will not, without authorization, access, tamper with, or use
            non-public areas of the Site, our computer systems, or the technical
            delivery systems of our service providers.
          </p>
          <p>
            (i) Except as necessary to maintain your own computer security by
            use of commercial-off-the-shelf anti-virus or anti-malware products,
            you will not attempt to probe, scan, or test the vulnerability of
            the Site or any other component of our system or network or breach
            any security or authentication measures.
          </p>
          <p>
            We are entitled to identify you to third parties who claim that
            their rights have been infringed by your Submission.
          </p>
          <p>
            Submissions are not considered to be confidential. You agree not to
            make any Submission in which you have any expectation of privacy. We
            do not claim any ownership rights in Submissions; however, by making
            a Submission you hereby grant us an irrevocable, perpetual, fully
            sub-licensable, non-exclusive, royalty-free, worldwide license to
            use, telecast, copy, perform, display, edit, distribute, reproduce,
            modify, adapt, and otherwise exploit the Submission, or any portion
            thereof, and any ideas, concepts, or know how contained therein,
            with or without attribution, and without the requirement of any
            permission from or payment to you or to any other person or entity,
            in any manner (including, without limitation, for commercial,
            publicity, trade, promotional, or advertising purposes) and in any
            and all media now known or hereafter devised, and to prepare
            derivative works of, or incorporate into other works, such
            Submission, and to grant and authorize sublicenses of the foregoing
            without any payment of money or any other form of consideration to
            you or to any third party. You represent and warrant that you own or
            otherwise control the rights to your Submission. You agree to
            indemnify and hold harmless us and our Affiliates (as defined below)
            for all costs, fees (including but not necessarily limited to
            attorney’s fees and costs) and claims arising from or in connection
            with any claims to any rights in your Submission or any damages
            arising from your Submission.
          </p>
          <p>
            <b> 9. Disclaimers &amp; Limitation of Liability </b>
          </p>
          <p>
            YOU USE THE SITE AND SERVICES AT YOUR OWN RISK. THE SITE AND
            SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR AFFILIATES,
            DISTRIBUTORS, SERVICE PROVIDERS, VENDORS, AND LICENSEES, AND OUR AND
            THEIR RESPECTIVE OFFICERS, EMPLOYEES, MANAGERS, DIRECTORS,
            SHAREHOLDERS, MEMBERS, PARENTS, SUBSIDIARIES, CORPORATE AFFILIATES,
            AGENTS, AND LICENSORS (REFERRED TO COLLECTIVELY AS “AFFILIATES”)
            DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH
            RESPECT TO THE SITE/SERVICES (INCLUDING THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, AND
            NON-INFRINGEMENT).
          </p>
          <p>
            IN PARTICULAR, WE AND OUR AFFILIATES MAKE NO REPRESENTATIONS OR
            WARRANTIES ABOUT THE AVAILABILITY, QUANTITY, ACCURACY, OR
            COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE SITE/SERVICES OR
            THE CONTENT OF ANY WEBSITES OR RESOURCES LINKED TO THE
            SITE/SERVICES. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE
            AND OUR AFFILIATES WILL HAVE NO LIABILITY FOR ANY: &nbsp;(A) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT; (B) PERSONAL INJURY OR
            PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SITE;
            (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR OF ANY
            PERSONAL OR FINANCIAL INFORMATION; (D) ANY INTERRUPTION OF
            TRANSMISSION TO OR FROM THE SITE; (E) ANY BUGS, VIRUSES, TROJAN
            HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR THROUGH THE SITE;
            OR (F) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
            USE OF ANY CONTENT POSTED, E-MAILED, TRANSMITTED, OR OTHERWISE MADE
            AVAILABLE ON OR THROUGH THE SITE/SERVICES.
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER WE NOR
            OUR AFFILIATES WILL BE LIABLE UNDER ANY THEORY OF LIABILITY FOR ANY
            DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
            DAMAGES, INCLUDING DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL,
            USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH PARTIES WERE
            ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
            DAMAGES), ARISING FROM OR RELATING TO USE OF THE SITE/SERVICES.
            WITHOUT LIMITING THE FOREGOING, IN NO EVENT SHALL OUR TOTAL
            LIABILITY TO YOU FOR ANY DAMAGES, LOSSES, AND CAUSES OF ACTION
            EXCEED FIVE DOLLARS ($5.00).
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of liability for incidental or
            consequential damages. Accordingly, some of the above limitations
            and disclaimers may not apply to you. To the extent we may not, as a
            matter of applicable law, disclaim any implied warranty or limit its
            liabilities, the scope and duration of such warranty and the extent
            of our liability will be the minimum permitted under such law.
          </p>
          <p><b> 10. Indemnity </b></p>
          <p>
            You agree to defend, indemnify, and hold us, our Affiliates,
            third-party service providers, employees, contractors, agents,
            officers, and directors harmless from any and all liabilities,
            claims, demands, suits, investigations, losses, judgments, costs,
            and expenses (including reasonable attorneys' fees and costs) that
            arise out of or are related to your violation or alleged violation
            of these Terms or use of the Site/Services.
          </p>
          <p>
            <b> 11. Dispute Resolution </b>
          </p>
          <p>
            Any controversy, claim or dispute arising out of or related to these
            Terms, the Site, or the Services, but not limited to, alleged
            violations of state or federal statutory or common law rights or
            duties (a “Dispute”) shall be solely and exclusively resolved
            according to the procedures set forth in this paragraph. If the
            parties are unable to resolve any Dispute through informal means,
            either party may initiate binding arbitration of such Dispute by
            sending notice demanding arbitration to the other party. The demand
            for arbitration shall be made within a reasonable time after the
            Dispute has arisen, but in no event shall it be made more than one
            year from when the aggrieved party knew or should have known of the
            controversy, claim, or facts forming the basis of the Dispute. The
            arbitration shall be initiated and conducted according to American
            Arbitration Association rules and procedures for consumer
            arbitration (if you are a consumer) or commercial arbitration (if
            you are a business) (the “Arbitration Rules”). The arbitration shall
            be conducted in Franklin County, Ohio before a single neutral
            arbitrator appointed in accordance with the Arbitration Rules with
            the option to appeal the arbitrator’s decision to an Optional
            Appellate Arbitration in accordance with the Arbitration Rules. The
            arbitrator shall not have the power to award punitive damages
            against any party. To the fullest extent permitted by applicable
            law, arbitration costs and fees shall be divided equally between the
            parties. To the fullest extent permitted by applicable law,
            attorneys’ fees shall be borne by each party independently and no
            party shall be liable for the attorneys’ fees of the other party.
            Notwithstanding the foregoing, if the arbitrator determines that a
            filed Dispute is frivolous, the arbitrator, applying applicable law,
            may award the prevailing party its attorneys’ fees and costs. No
            Disputes may be arbitrated on a class or representative basis and
            the arbitrator may not consolidate or join the claims of other
            persons or parties who may be similarly situated. BY AGREEING TO
            THESE TERMS, EACH PARTY IRREVOCABLY WAIVES ANY RIGHT IT MAY HAVE TO
            JOIN CLAIMS OR DISPUTES WITH THOSE OF OTHERS IN THE FORM OF A CLASS
            ACTION, CLASS ARBITRATION, OR SIMILAR PROCEDURAL DEVICE; WAIVES ANY
            RIGHT IT MAY HAVE TO PRESENT ITS CLAIM OR DISPUTE IN A COURT OF LAW;
            AND WAIVES ANY RIGHT TO BRING A DISPUTE MORE THAN ONE YEAR FROM WHEN
            IT KNEW OR SHOULD HAVE KNOWN OF THE CONTROVERSY, CLAIM, OR FACTS
            FORMING THE BASIS OF THE DISPUTE. Judgment on the award rendered by
            the arbitrator(s), if any, may be entered for enforcement purposes
            in any court having jurisdiction thereof.
          </p>
          <p>
            Any matters submitted to a court for resolution shall be submitted
            to the state or federal courts of Franklin County, Ohio and all
            parties agree to the personal jurisdiction thereof.
          </p>
          <p>
            <b> 12. Privacy &amp; Security </b>
          </p>
          <p>
            We maintain exclusive control of access and right of access to the
            Site/Services. You understand and agree that we reserve the right to
            revoke your access at any time without notice or cause of action for
            any reason whatsoever.
          </p>
          <p><b> 13. Links </b></p>
          <p>
            The Site/Services may contain links to sites or be accessed by links
            from sites that are owned and operated by independent third parties
            to which these Terms do not apply. We provide links solely as a
            convenience and the inclusion of the link does not imply that we
            endorse or accept any responsibility for the content on those sites.
            We are not responsible for content including but not limited to
            claims, representations, warranties, offers, illustrations, names,
            or endorsements on any other sites. Further, we are not, directly or
            indirectly, implying any approval, association, sponsorship,
            endorsement, or affiliation with the linked site, unless
            specifically stated therein, although any such affiliation may be
            outdated, so please check directly with us for most current
            information in this regard. Your linking to any other off-site pages
            or other sites is at your own risk. We recommend that you review any
            terms of use statement and privacy policy before using any other
            linked site, including social media sites.
          </p>
          <p>
            <b> 14. Contact Information </b>
          </p>
          <p>
            <b> Disclaimer of Warranties </b>
          </p>
          <p>
            The Program and all information conveyed by it are provided to you
            on an “AS-IS” and “AS AVAILABLE” basis, and AT YOUR OWN RISK TO THE
            FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW. To the fullest extent
            permitted under applicable law, we disclaim all warranties, whether
            express or implied, including without limitation, any warranties of
            title, merchantability, non-infringement and fitness for a
            particular purpose and all warranties regarding security, currency,
            correctness, quality, accuracy, completeness, reliability,
            performance, timeliness, or continued availability. We are not
            responsible for cellular network or equipment performance, and you
            release us of any liability for claims based on hardware, software,
            electronic, network or other communications malfunctions such as
            incomplete messages, delayed transmissions or any technical
            difficulty that may limit your ability to send or receive a message.
          </p>
        </div>
      </el-container>
    </el-main>
  </div>
</template>

<script>
import { Container } from "element-ui";
Vue.use(Container);
export default {
  name: 'TermsofUse',
  components: {
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";
@import "../assets/scss/text.scss";
</style>
